<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="integration.name" />
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-xl-3 col-lg-4">
            <div class="card">
              <div class="card-body">
                <div class="profile-card text-center">
                  <div class="thumb-xl member-thumb m-b-10 center-block">
                    <img
                      :src="`img/integrations/${integration.logo}`"
                      alt="profile-image"
                    />
                  </div>
                  <ul class="list-reset text-left m-b-40 m-t-40">
                    <li class="text-muted">
                      <strong :style="'textTransform: uppercase;'">{{$t('generic-str.provider')}}</strong>
                      <a
                        :href="integration.provider_url"
                        target="_blank"
                        class="m-l-15"
                      >
                        {{ integration.provider_name }}
                        <div class="icon dripicons-link"></div>
                      </a>
                    </li>
                    <li class="text-muted">
                      <strong :style="'textTransform: uppercase;'">{{$tc('generic-str.category', 1)}}</strong>
                      <span class="m-l-15">
                        <template v-if="integration.category == 'e-commerce'">
                          E-Commerce
                        </template>
                      </span>
                    </li>
                    <li class="text-muted">
                      <strong>ID</strong>
                      <span class="m-l-15">{{ integration.id }}</span>
                    </li>
                  </ul>
                  <button
                    v-if="integration.installed"
                    type="button"
                    class="btn btn-primary btn-rounded"
                    @click="uninstall"
                  >
                    {{$t('generic-str.uninstall')}}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-primary btn-rounded"
                    @click="install"
                  >
                    {{$t('generic-str.Install')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-8">
            <div class="card card-tabs">
              <div class="card-header p-0 no-border">
                <ul class="nav nav-tabs primary-tabs p-l-30 m-0">
                  <li
                    v-if="integration.installed"
                    class="nav-item"
                    role="presentation"
                  >
                    <a
                      href="javascript:void(0)"
                      v-tab="'configure'"
                      class="nav-link active"
                      data-toggle="tab"
                      aria-expanded="true"
                      >{{$t('generic-str.config')}}</a
                    >
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      href="javascript:void(0)"
                      v-tab="'description'"
                      class="nav-link"
                      :class="{ active: !integration.installed }"
                      data-toggle="tab"
                      aria-expanded="true"
                      >{{$t('departments.data-infos.lbl-description')}}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="tab-content">
                <div
                  v-if="integration.installed"
                  class="tab-pane active"
                  id="configure"
                >
                  <form @submit.prevent="save" class="form-horizontal">
                    <div class="card-body">
                      <div class="form-body">
                        <h5 class="card-title text-left">{{$t('generic-str.auth')}}</h5>
                        <div class="form-group row">
                          <label
                            class="control-label text-left col-md-4 weight-500"
                            >{{$t('integration-detail-component.id-install')}}</label
                          >
                          <div class="col-md-8">
                            {{ integration.installed.id }}
                          </div>
                        </div>
                        <div
                          v-for="(input, key) in form.auth_data"
                          :key="key"
                          class="form-group row"
                        >
                          <label
                            class="control-label text-left col-md-4 weight-500 text-uppercase"
                            >{{ key }}</label
                          >
                          <div class="col-md-5">
                            <input
                              v-model="form.auth_data[key]"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <hr class="dashed" />
                        <h4 class="card-title text-left">{{$tc('generic-str.message', 2)}}</h4>
                        <p v-html="$t('integration-detail-component.msg-description')"></p>
                        <div class="form-row hide-m">
                          <div class="col-lg-3 col-md-3 col-sm-12">
                            <label>Status</label>
                          </div>
                          <div class="col-lg-9 col-md-9 col-sm-12">
                            <label> {{$tc('generic-str.message', 1)}} </label>
                          </div>
                        </div>
                        <div
                          v-for="(input, key) in form.settings"
                          class="form-row"
                          :key="key"
                        >
                          <div class="form-group col-lg-3 col-md-3 col-sm-12">
                            <input
                              v-model="input.field"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group col-lg-8 col-md-8 col-sm-12">
                            <input
                              v-model="input.value"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group col-1">
                            <button
                              type="button"
                              @click="removeSetting(key)"
                              class="btn btn-danger btn-sm"
                            >
                              <i class="icon dripicons-trash color-white"></i>
                            </button>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-lg-3 col-md-3 col-sm-12">
                            <button
                              type="button"
                              @click="addSetting()"
                              class="btn btn-success btn-sm"
                            >
                              {{$t('generic-str.add')}}
                              <i class="icon dripicons-plus color-white"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer bg-light">
                      <button
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        :disabled="isSending"
                        type="submit"
                        class="btn btn-primary"
                      >
                        {{$t('generic-str.save')}}
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  class="tab-pane"
                  id="description"
                  :class="{ active: !integration.installed }"
                >
                  <div class="card-body">
                    <div class="profile-wrapper p-t-20">
                      <h5 class="card-title">{{$t('integration-detail-component.about')}}</h5>
                      <p>{{$t('integration-detail-component.description')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import IntegrationService from '@/services/integration.service';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'IntegrationDetail',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      integration: {},
      form: {},
      pages: 1,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch() {
      this.fetched = false;
      IntegrationService.getIntegration(this.$route.params.id).then(
        (response) => {
          this.integration = response.data;
          if (response.data.installed) {
            this.form = {
              auth_data: response.data.installed.auth_data,
              settings: response.data.installed.settings,
            };
          }
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    install() {
      this.fetched = false;
      IntegrationService.installIntegration(this.$route.params.id).then(
        (integration) => {
          this.integration = integration;
          this.form = {
            auth_data: integration.installed.auth_data,
            settings: integration.installed.settings,
          };
          this.fetched = true;
        },
      );
    },
    uninstall() {
      this.fetched = false;
      IntegrationService.uninstallIntegration(this.$route.params.id).then(
        (response) => {
          this.integration = response;
          this.form = {};
          this.fetched = true;
        },
      );
    },
    addSetting() {
      this.form.settings.push({
        field: this.$t('warnings.integration-detail-component.created'),
        value: 'Ola {name}, seu pedido {id} foi recebido com sucesso!',
      });
    },
    removeSetting(n) {
      this.form.settings.splice(n, 1);
    },
    save() {
      this.isSending = true;
      IntegrationService.configureIntegration(
        this.integration.installed.id,
        this.form,
      )
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.integration-detail-component.success'),
              type: 'success',
            });
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.blockquote-footer,
.text-muted {
  margin: 10px 0px;
}
</style>
